@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?ion0et');
	src:url('../fonts/icomoon.eot?#iefixion0et') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?ion0et') format('truetype'),
		url('../fonts/icomoon.woff?ion0et') format('woff'),
		url('../fonts/icomoon.svg?ion0et#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mouse:before {
	content: "\e60a";
}
.icon-mobile:before {
	content: "\e60b";
}
.icon-tablet:before {
	content: "\e60c";
}
.icon-monitor:before {
	content: "\e60d";
}
.icon-clock:before {
	content: "\e600";
}
.icon-settings:before {
	content: "\e601";
}
.icon-light:before {
	content: "\e602";
}
.icon-tactick:before {
	content: "\e603";
}
.icon-menu:before {
	content: "\e60e";
}
.icon-email:before {
	content: "\e615";
}
.icon-phone:before {
	content: "\e614";
}
.icon-arrow-up:before {
	content: "\e606";
}
.icon-arrow-down:before {
	content: "\e607";
}
.icon-arrow-left:before {
	content: "\e608";
}
.icon-arrow-right:before {
	content: "\e609";
}
.icon-close:before {
	content: "\e60f";
}
.icon-plus:before {
	content: "\e610";
}
.icon-googleplus:before {
	content: "\e616";
}
.icon-youtube:before {
	content: "\e613";
}
.icon-linkedin:before {
	content: "\e611";
}
.icon-twitter:before {
	content: "\e605";
}
.icon-facebook:before {
	content: "\e604";
}
.icon-marker:before {
	content: "\e612";
}
